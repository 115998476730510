import React from 'react';
import Header from '../../components/Header';
import ImageSlider from '../../components/ImageSlider';
import Footer from '../../components/Footer';

function PrivacyPolicy() {
  return (
    <div>
          <Header/>
          <ImageSlider/>
          <div style={{ margin: '64px 128px' }}>
            

                <h2>Privacy Policy</h2>
                Effective Date: 9/16/2024

                <p>
                1. Introduction

                Sunshine Preschool ("we," "our," or "us") values your privacy. 
                This Privacy Policy explains how we collect, use, disclose, and safeguard your personal 
                information when you visit our website (https://sunshinepreschool1-2.org/). 
                Please read this privacy policy carefully.
                </p>
                <p>
                2. Information We Collect

                We may collect the following types of information:

                Personal Identification Information: Name, email address, phone number, and other contact details.
                Child Information: Child's name, age, and enrollment details.
                Usage Data: Information such as IP address, browser type, access times, and pages viewed.
                </p>
                <p>
                3. How We Use Your Information

                We use the information we collect in the following ways:

                To communicate with you regarding enrollment or your child’s care.
                To provide and improve the services offered by our daycare.
                To send periodic emails related to our services, special events, or important updates.
                </p>
                <p>
                4. How We Protect Your Information

                We implement appropriate data security measures to protect your personal data. However, 
                no method of transmission over the Internet is 100% secure. We cannot guarantee absolute security.
                </p>
                <p>
                5. Sharing Your Information

                We do not sell or share personal information with third parties, except:

                To comply with legal obligations.
                If you give us explicit consent to do so.
                </p>
                <p>
                6. Your Rights

                You have the right to access, correct, or delete your personal information. You may also 
                opt-out of receiving any communications from us at any time.
                </p>
                <p>
                7. Changes to This Privacy Policy

                We may update this privacy policy from time to time. Any changes will be posted on this page 
                with an updated effective date.
                </p>
                <p>
                8. Contact Us

                If you have any questions about this Privacy Policy, please contact us at:

                </p>
                <p>
                <br/><br/><br/>
            Sunshine Preschool
            <br/><br/>
            [Phone Number]
            <br/>
            [Email Address]
            <br/>
            [Mailing Address]
            </p>
            
          </div>
          
          
          <Footer/>
      </div>
  );
}

export default PrivacyPolicy;