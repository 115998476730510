import React from 'react';
import Header from '../../components/Header';
import ImageSlider from '../../components/ImageSlider';
import Footer from '../../components/Footer';

function TermsofUse() {
  return (
    <div>
          <Header/>
          <ImageSlider/>
          <div style={{ margin: '64px 128px' }}>
            
            <h2>Terms of Use</h2>
            Effective Date: 9/16/2024
            <p>
            1. Acceptance of Terms

            By accessing or using the Sunshine Preschool website (https://sunshinepreschool1-2.org/), you agree to comply with these terms and conditions. If you do not agree, please do not use this site.
            </p>
            <p>
            2. Use of Website

            You may use the website for personal, non-commercial purposes only. You may not:

            Use the site for any unlawful purpose.
            Interfere with the site’s functionality or security.
            Attempt to gain unauthorized access to the site’s data or systems.
            </p>
            <p>
            3. User Submissions

            If you provide information through our website (such as forms for enrollment or inquiries), you agree that all information provided is accurate and up to date. We reserve the right to contact you based on the information submitted.
            </p>
            <p>
            4. Intellectual Property

            All content on this website, including text, images, graphics, and logos, is the property of Sunshine Preschool and is protected by copyright laws. You may not use or reproduce any content without written permission.
            </p>
            <p>
            5. Disclaimer

            The website is provided "as is," without warranties of any kind, either expressed or implied. We do not guarantee that the website will always be available or error-free.
            </p>
            <p>
            6. Limitation of Liability

            In no event will Sunshine Preschool be liable for any damages arising from the use or inability to use the website, including any direct, indirect, or consequential damages.
            </p>
            <p>
            7. Changes to Terms

            We reserve the right to modify these terms at any time. Any changes will be posted on this page with an updated effective date.
            </p>
            <p>
            8. Governing Law

            These terms shall be governed and construed in accordance with the laws of California.
            </p>
            <p>
            9. Contact Us

            If you have any questions about these Terms of Use, please contact us at:

            <br/><br/><br/>
            Sunshine Preschool
            <br/><br/>
            [Phone Number]
            <br/>
            [Email Address]
            <br/>
            [Mailing Address]
            </p>
            
          </div>
          
          
          <Footer/>
      </div>
  );
}

export default TermsofUse;