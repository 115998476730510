import React from 'react';
import './styles.css';

function Submenu() {
  return (
    <div className="submenu-container">
      <a href="#section1" className="submenu-item">Section 1</a>
      <a href="#section2" className="submenu-item">Section 2</a>
      <a href="#section3" className="submenu-item">Section 3</a>
      <a href="#section4" className="submenu-item">Section 4</a>
      <a href="#section5" className="submenu-item">Section 5</a>
    </div>
  );
}

export default Submenu;