import React from 'react'
import './styles.css'

const MissionStatement = () => {

    return (
        <div className='main-container'>
            <h2>Mission Statement</h2>
            <p>
                Sunshine is dedicated to meeting the individual needs of children and families, 
                fostering a sense of pride in self and community, as it upholds best practices in 
                the field of Early Care and Education, centered on the whole-child approach to 
                learning.
                As educators, our mission is to provide a safe and developmentally 
                appropriate learning environment, which fosters a child’s natural desire to 
                explore, discover, create, and become a lifelong learner. 
                Establishing and maintaining secure connections with the children in our care, 
                teaches them that they are part of a caring community that will continue to 
                watch them grow with love. We believe respect, diversity, and inclusivity is 
                very important in Early Childhood Education.
                Sunshine is committed to providing an inclusive, and 
                welcoming environment for all members of our staff, children, families, 
                volunteers, subcontractors, and vendors. We do not discriminate against anyone 
                on the basis of race, creed, color, ethnicity, national origin, religion, sexual 
                orientation, gender expression, age, physical or mental ability, veteran status, 
                military obligations, and marital status, in any of its activities or operations. 
                At Sunshine, we aim to provide a learning environment that is inviting, accepting, 
                understanding & ever-growing.
                As educators, our mission is to provide a safe and developmentally 
                appropriate learning environment, which fosters a child’s natural desire to 
                explore, discover, create, and become a lifelong learner.
                Establishing and maintaining secure connections with the children in our care, 
                teaches them that they are part of a caring community that will continue to 
                watch them grow with love. We believe respect, diversity, and inclusivity is 
                very important in Early Childhood Education.
        
                Sunshine is committed to providing an inclusive, and 
                welcoming environment for all members of our staff, children, families, 
                volunteers, subcontractors, and vendors. We do not discriminate against anyone 
                on the basis of race, creed, color, ethnicity, national origin, religion, sexual 
                orientation, gender expression, age, physical or mental ability, veteran status, 
                military obligations, and marital status, in any of its activities or operations. 
                At Sunshine, we aim to provide a learning environment that is inviting, accepting, 
                understanding & ever-growing.
            </p>
        </div>
    )
}

export default MissionStatement;